import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  Suspense,
  lazy,
} from "react";
import useAuth from "../stores/authStore";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../helper/firebaseClient";
import FileView from "../components/FileView";

// Lazy load components for perf gains :)
const ChatSidebar = lazy(() => import("../components/ChatSidebar"));
const Input = lazy(() => import("../components/ChatInput"));
const ChatMessages = lazy(() => import("../components/ChatMessages"));
const FilesInChat = lazy(() => import("../components/FilesInChat"));
const ShowAttach = lazy(() => import("../components/dialogs/ShowAttach"));
const ChatSearchBar = lazy(
  () => import("../components/chatsPage/ChatSearchbar")
);

// Todo: Replace with a Skeleton component
const LoadingPlaceholder = () => <div>Loading...</div>;

function Chat() {
  const [currChat, setCurrChat] = useState({
    chatId: "null",
    user: {},
    dept: "",
  });
  const [messages, setMessages] = useState([]);
  const [showSideBar, setShowSideBar] = useState(true);
  const [showAttach, setShowAttach] = useState(false);
  const [file, setFile] = useState(null);
  const [selFile, setSelFile] = useState(null);
  const [fileViewIsOpen, setSetFileViewIsOpen] = useState(false);

  const profileData = useAuth((state) => state.profileData);

  const updateCurrChat = useCallback(
    (type, data) => {
      console.log({ type, data });
      if (type === "user") {
        setCurrChat({
          user: data,
          chatId:
            data.id < profileData.id
              ? data.id + profileData.id
              : profileData.id + data.id,
        });
      } else {
        setCurrChat({
          chatId: data.id,
          name: data.name,
        });
      }
      setShowSideBar(false);
    },
    [profileData.id]
  );

  useEffect(() => {
    if (currChat.chatId === "null") return;

    const unsub = onSnapshot(doc(db, "chats", currChat.chatId), (doc) => {
      if (doc.exists()) {
        setMessages(doc.data().messages);
      } else {
        setMessages([]);
      }
    });

    return unsub;
  }, [currChat.chatId]);

  const handleBack = useCallback(() => setShowSideBar(true), []);

  const renderChatHeader = useMemo(() => {
    if (currChat.chatId === "null") return null;

    return (
      <div
        className="flex items-center h-16 bg-[#F1F1FF]"
        style={{ borderBottom: "0.25px solid grey" }}
      >
        <h1 className="md:hidden" onClick={handleBack}>
          Back
        </h1>
        {currChat.user ? (
          <div className="flex justify-between w-11/12">
            <div className="flex items-center">
              <img
                src={currChat.user?.profilePictureUrl || ""}
                className="h-8 rounded-lg ml-4"
                alt="user"
              />
              <div className="ml-4">
                <h1 className="font-bold">{currChat.user.name}</h1>
                <p className="text-sm">Status</p>
              </div>
            </div>
            <Suspense fallback={<LoadingPlaceholder />}>
              <ChatSearchBar currChat={messages} />
            </Suspense>
          </div>
        ) : (
          <div className="ml-4">
            <h1 className="font-bold">{currChat.name}</h1>
          </div>
        )}
      </div>
    );
  }, [currChat, messages, handleBack]);
  if (fileViewIsOpen) {
    console.log({ fileViewIsOpen });
    return (
      <FileView
        fileInfo={selFile}
        closeDrawer={() => setSetFileViewIsOpen(false)}
        sharedFileInfo={selFile}
      />
    );
  }
  return (
    <div className="flex w-screen h-screen overflow-y-hidden">
      <Suspense fallback={<LoadingPlaceholder />}>
        <ChatSidebar
          updateCurrChat={updateCurrChat}
          showSideBar={showSideBar}
          handleBack={handleBack}
        />
      </Suspense>
      {currChat.chatId === "null" ? (
        <div className="w-full p-4">Start a conversation!</div>
      ) : (
        <div
          className={`h-screen flex flex-col justify-between md:w-full ${
            showSideBar ? "hidden" : "flex"
          } md:flex`}
        >
          {renderChatHeader}
          <div className="h-full overflow-y-auto">
            <Suspense fallback={<LoadingPlaceholder />}>
              {messages.map((message) => (
                <ChatMessages
                  key={message.id}
                  message={message}
                  selFile={selFile}
                  setSelFile={setSelFile}
                  setSetFileViewIsOpen={setSetFileViewIsOpen}
                  currChat={currChat}
                  file={file}
                  setFile={setFile}
                />
              ))}
            </Suspense>
          </div>
          <Suspense fallback={<LoadingPlaceholder />}>
            <Input
              currChat={currChat}
              showAttach={showAttach}
              setShowAttach={setShowAttach}
            />
          </Suspense>
        </div>
      )}
      <div className="w-[60%] h-full overflow-y-auto">
        <Suspense fallback={<LoadingPlaceholder />}>
          <FilesInChat file={file} />
        </Suspense>
      </div>
      {showAttach && (
        <Suspense fallback={<LoadingPlaceholder />}>
          <ShowAttach
            open={showAttach}
            closeDialog={() => setShowAttach(false)}
            currChat={currChat}
          />
        </Suspense>
      )}
    </div>
  );
}

export default React.memo(Chat);
