import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  applyActionCode,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";
import { auth } from "../helper/firebaseClient";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// images from public folder
const logo = `${process.env.PUBLIC_URL}/assets/logo.webp`;

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [oobCode, setOobCode] = useState("");
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract oobCode and mode from query params
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get("oobCode");
    const mode = query.get("mode");
    setOobCode(code || "");
    setMode(mode || "");
  }, [location]);

  const handlePasswordReset = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    try {
      await verifyPasswordResetCode(auth, oobCode);
      await confirmPasswordReset(auth, oobCode, password);
      setMessage("Password has been reset successfully");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      if (error.code === "auth/invalid-action-code") {
        setError(
          "This link has already been used. Please click forgot password again."
        );
      } else {
        setError(error.message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEmailVerification = async () => {
    try {
      await applyActionCode(auth, oobCode);
      setMessage("Email has been verified successfully");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setError(error.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setMessage("");

    if (mode === "resetPassword") {
      handlePasswordReset();
    } else if (mode === "verifyEmail") {
      handleEmailVerification();
    } else {
      setError("Invalid action mode");
      setLoading(false);
    }
  };

  return (
    <div
      className="flex justify-center items-center font-raleway h-screen"
      style={{
        backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/twokey-a14ec.appspot.com/o/backdrop.svg?alt=media&token=b608f0cb-f474-4b8d-ba73-2be49ffaabfa"})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat", // Do not repeat the image
      }}
    >
      <form
        onSubmit={handleSubmit}
        className="border-2 rounded-xl border-indigo-100 p-12 bg-white w=full md:w-1/3"
      >
        {mode === "resetPassword" && (
          <>
            <img
              src={logo}
              alt="logo"
              className="h-4"
              style={{ transform: "translateX(-3px)" }}
            />
            <h1 className="self-start mt-5 text-lg">Reset Password 🔐</h1>
            <p className="text-xs">Enter your new password</p>
            <div className="flex mt-5">
              <div className="w-full mb-4 mr-4">
                <InputLabel
                  htmlFor="password"
                  className="text-md text-left mb-2"
                >
                  New Password
                </InputLabel>
                <TextField
                  type={showPassword ? "text" : "password"}
                  id="password"
                  variant="outlined"
                  className="w-full bg-gray-100"
                  placeholder="Enter new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size="small"
                  required
                />
              </div>
              <div className="w-full mb-4">
                <InputLabel
                  htmlFor="confirmPassword"
                  className="text-md text-left mb-2"
                >
                  Confirm Password
                </InputLabel>
                <TextField
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  variant="outlined"
                  className="w-full bg-gray-100"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  size="small"
                  required
                />
              </div>
            </div>
            <p className="text-xs">
              Use 8 or more characters with a mix of letters, numbers & symbols
            </p>

            <div className="mt-2 text-xs">
              <FormControlLabel
                control={
                  <Checkbox onClick={() => setShowPassword(!showPassword)} />
                }
                label={<span style={{ fontSize: "14px" }}>Show Password</span>}
              />
            </div>
          </>
        )}

        {mode === "verifyEmail" && (
          <h2 className="text-left text-lg font-semibold mb-4">
            Click the button to verify you email
          </h2>
        )}

        {loading ? (
          <div className="text-center">
            <CircularProgress
              style={{ color: "#000", height: 25, width: 25 }}
            />
          </div>
        ) : (
          <div className="text-center">
            <button
              type="submit"
              disabled={loading}
              className="bg-black border rounded-xl py-2.5 px-8 text-sm font-semibold w-full mt-4 text-white"
            >
              {mode === "resetPassword" ? "Save Password" : "Verify Email"}
            </button>
          </div>
        )}

        {error && <p className="text-red-500 text-left mt-2">{error}</p>}
        {message && <p className="text-green-500 text-left mt-2">{message}</p>}
      </form>
    </div>
  );
};

export default ResetPassword;
