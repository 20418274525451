import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { DarkModeProvider } from "./context/darkModeContext";
// import { AuthProvider } from "./context/authContext";
import { DepartmentProvider } from "./context/departmentContext";
import { FileState } from "./context/fileContext";
import { Analytics } from "@vercel/analytics/react";
import DepartmentLoader from "./components/departments/DepartmentLoader";

// import { registerLicense } from "@syncfusion/ej2-base";
// registerLicense(
//   "Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXZcdnRSRmdfVUJ/X0U="
// );
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <AuthProvider>
    <DarkModeProvider>
      <DepartmentLoader />
      {/* <DepartmentProvider> */}
      <FileState>
        <App />
        <Analytics />
      </FileState>
      {/* </DepartmentProvider> */}
    </DarkModeProvider>
  // </AuthProvider>
);
