import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../helper/firebaseClient";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

// images from public folder
const bg = `${process.env.PUBLIC_URL}/assets/auth/backdrop.webp`;
const logo = `${process.env.PUBLIC_URL}/assets/logo.webp`;

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    async function logout() {
      secureLocalStorage.clear();
      await auth.signOut();
    }
    logout();
  }, []);

  const handleChange = (event) => {
    setError("");
    setEmail(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (email.trim() === "") {
      setError("Please enter your email.");
      setLoading(false);
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent.");
      setError("");
    } catch (error) {
      setError(error.message || "Something went wrong");
      setMessage("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="flex justify-center items-center font-raleway h-screen"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat", // Do not repeat the image
      }}
    >
      <form
        onSubmit={handleSubmit}
        className="border-2 rounded-xl border-indigo-100 p-8 bg-white w-full md:w-1/3"
      >
        <div className="w-full">
          <img
            src={logo}
            alt="logo"
            className="h-4"
            style={{ transform: "translateX(-3px)" }}
          />
          <h1 className="self-start mt-5 text-lg">Reset Password 🔐</h1>
          <p className="text-xs">
            To verify your identity, we’ll send a verification code to the
            following email
          </p>
          <InputLabel className="text-md text-left mb-2 mt-5" htmlFor="email">
            Email
          </InputLabel>
          <span id="email" className="flex flex-row gap-2">
            <TextField
              id="outlined-basic-email"
              variant="outlined"
              className="w-full bg-gray-100"
              placeholder="Enter your Email here"
              name="email"
              value={email}
              onChange={handleChange}
              size="small"
              autoFocus
            />
          </span>
        </div>

        {loading ? (
          <div className="text-center">
            <CircularProgress
              style={{ color: "#000", height: 25, width: 25 }}
            />
          </div>
        ) : (
          <div className="text-center">
            <button
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
              className="bg-black border rounded-xl py-2.5 px-8 text-sm font-semibold w-full mt-4 text-white"
            >
              Next
            </button>
          </div>
        )}

        {error && <p className="text-red-500 text-left mt-2">{error}</p>}
        {message && <p className="text-green-600 text-left mt-2">{message}</p>}

        <p className="text-gray-500 mt-4">
          Or proceed to{"  "}
          <Link to="/login" className="text-indigo-600 font-semibold">
            Login
          </Link>
        </p>
      </form>
    </div>
  );
};

export default ForgotPassword;
