import React, { Suspense } from "react";
import { useDarkMode } from "../context/darkModeContext";
import { Dialog } from "@mui/material";
import useAuth from "../stores/authStore";
import DueDate from "../components/DueDate";
import LatestActivities from "../components/LatestActivities";
import LatestEmails from "../components/LatestEmails";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { Link } from "react-router-dom";
import { LoadingSection } from "../utils/loading";

const DashboardFolders = React.lazy(
  () => import("../components/DashboardFolders")
);

const DashboardTabs = React.lazy(() => import("../components/DashboardTabs"));

const FileViewer = React.lazy(() => import("../components/FileViewer"));

const Dashboard = () => {
  const { darkMode } = useDarkMode();
  const isFileViewerOpen = useAuth((state) =>state.isFileViewerOpen);
    const closeFileViewer = useAuth((state) =>state.closeFileViewer);

  return (
    <div
      className={`w-full p-4 h-full overflow-clip ${
        darkMode ? "bg-gray-800 text-white" : "text-gray-800"
      }`}
    >
      <Link
        to="/chat"
        className="fixed bottom-5 right-5 z-10 h-16 w-16 bg-[#4A3AFF] rounded-full flex items-center justify-center "
      >
        <ChatBubbleOutlineOutlinedIcon className="text-white" />
      </Link>
      <div className="flex flex-col md:flex-row gap-4">
        <DueDate />
        <div className="w-full flex flex-col gap-4">
          <LatestActivities />
          <LatestEmails />
        </div>
      </div>
      <div>
        <Suspense fallback={<LoadingSection height={240} />}>
          <DashboardFolders />
        </Suspense>
        <Suspense fallback={<LoadingSection height={560} />}>
          <DashboardTabs />
        </Suspense>
      </div>
      {/* <div className={`${screenshotDetected ? "blur" : ""}`}> */}
      <Dialog open={isFileViewerOpen} onClose={closeFileViewer} maxWidth="lg">
        <Suspense fallback={<LoadingSection />}>
          <FileViewer onClose={closeFileViewer} />{" "}
        </Suspense>
      </Dialog>
      {/* </div> */}
    </div>
  );
};

export default Dashboard;
